<template>
  <base-page-item>
    <v-card class="pa-16 ma-16">
      <v-row>
        <v-col
          v-for="(s, i) in sections"
          :key="`section_${i}`"
          cols="auto"
        >
          <v-row>
            <v-col
              cols="auto"
            >
              <p
                v-for="key in s"
                :key="`key_${key}`"
                class="font-weight-bold"
              >
                {{ key }}
              </p>
            </v-col>
            <v-col cols="auto">
              <p
                v-for="key in s"
                :key="`value_${key}`"
              >
                {{ [null, undefined, ''].includes(item[key]) ? 'No value' : item[key] }}
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </base-page-item>
</template>

<script>
  import BasePageItem from '@/components/base/BasePageItem';

  export default {
    name: 'BaseLogItem',
    components: {
      BasePageItem,
    },
    props: {
      item: {
        type: Object,
        default: () => {},
      },
      sections: {
        type: Array,
        default: () => [],
      },
    },
  };
</script>

<style scoped></style>
