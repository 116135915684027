<template>
  <v-autocomplete
    v-model="select"
    :items="items"
    :loading="isLoading"
    :item-text="itemText"
    item-value="_id"
    :label="label"
    :filter="customFilter"
    persistent-hint
    return-object
    single-line
  >
    <template #append-item>
      <div v-intersect="lazyLoad" />
    </template>
  </v-autocomplete>
</template>

<script>
  import { selectorsMixin } from '@/mixins/selectors/selectorsMixin';

  export default {
    name: 'BaseSelectorAutocomplete',
    mixins: [selectorsMixin],
    props: {
      isLoading: {
        type: Boolean,
        default: false,
      },
      initialValue: {
        type: Object,
        default: () => null,
      },
      itemText: {
        type: String,
        default: 'full_name',
      },
      itemValue: {
        type: String,
        default: '_id',
      },
      customFilter: {
        type: Function,
        default (obj, query, value) {
          const index = value.toLowerCase().indexOf(query.toLowerCase());
          if (index > -1) {
            if (index === 0) {
              return true;
            }
            return index;
          }
          return false;
        },
      },
    },
    methods: {
      lazyLoad (entry, observer, isIntersecting) {
        if (isIntersecting) {
          this.$emit('lazy-load');
        }
      },
    },
  };
</script>

<style scoped>

</style>
