export const queryFiltersMixin = {
  methods: {
    setQueryByFilters (filtersObject, byValue = true) {
      const urlParams = {};
      Object.entries(filtersObject).forEach(([key, v]) => {
        if (byValue) {
          if (v.value) {
            urlParams[key] = JSON.stringify(v);
          }
        } else {
          if (v) {
            urlParams[key] = JSON.stringify(v);
          }
        }
      });
      const params = new URLSearchParams(urlParams).toString();
      window.history.replaceState(null, null, `${this.$route.path}?${params.toString()}`);
    },
    setFiltersByQuery (filterPath) {
      const { query } = this.$route;
      Object.entries(query).forEach(([key, v]) => {
        this.$store.dispatch(filterPath, { [key]: JSON.parse(decodeURIComponent(v)) });
      });
      // const params = new URLSearchParams(urlParams).toString();
      // window.history.replaceState(null, null, `${this.$route.path}?${params.toString()}`);
    },
  },
};
