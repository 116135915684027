export const lazyLoadMixin = {
  data () {
    return {
      loading: false,
      canLoadMore: true,
    };
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
