<template>
  <v-lazy
    v-model="isActive"
    :options="{
      threshold: .5
    }"
    transition="fade-transition"
    min-height="50"
    class="mb-5"
  >
    <slot />
  </v-lazy>
</template>

<script>
  export default {
    name: 'BasePageItem',
    data () {
      return {
        isActive: false,
      };
    },
  };
</script>

<style scoped>

</style>
