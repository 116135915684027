export const selectorsMixin = {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'Select',
    },
  },
  data () {
    return {
      select: this.initialValue,
    };
  },
  watch: {
    select: function (newSelect) {
      this.$emit('select', newSelect);
    },
  },
};
