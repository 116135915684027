<template>
  <v-col>
    <p class="mb-2 font-weight-bold">
      Search
    </p>
    <v-row
      class="row mb-2"
    >
      <template
        v-for="(f, k) in filters"
      >
        <base-selector-autocomplete
          v-if="f.items"
          v-show="f.show || f.show === undefined"
          :key="k"
          :items="f.items"
          item-text="label"
          :label="f.label"
          class="col-2"
          @select="newSelect => f.value = newSelect._id"
        />
        <v-checkbox
          v-else-if="typeof f.value === 'boolean'"
          v-show="f.show || f.show === undefined"
          :key="k"
          v-model="f.value"
          class="col-2"
          :label="f.label"
        />
        <v-text-field
          v-else
          v-show="f.show || f.show === undefined"
          :key="k"
          v-model="f.value"
          class="col-2"
          :rules="f.rules || []"
          :label="f.label"
        />
      </template>
      <v-btn
        @click="search"
      >
        Search
      </v-btn>
    </v-row>
    <base-log-item
      v-for="proxy in proxyList"
      :key="proxy._id"
      :item="proxy"
      :sections="sections"
    />

    <v-progress-linear
      v-if="loading"
      color="deep-purple accent-4"
      indeterminate
      rounded
      height="6"
    />
  </v-col>
</template>
<script>
  import { lazyLoadMixin } from '@/mixins/lazyLoadMixin';
  import { mapGetters } from 'vuex';
  import baseLogItem from '../base/baseLogItem.vue';
  import { queryFiltersMixin } from '@/mixins/QueryFiltersMixin';
  import BaseSelectorAutocomplete from '../base/selectors/BaseSelectorAutocomplete.vue';
  export default {
    name: 'ProxyList',
    components: { baseLogItem, BaseSelectorAutocomplete },
    mixins: [lazyLoadMixin, queryFiltersMixin],

    data () {
      return {
        filters: {
          proxy_address: {
            label: 'Proxy address',
            value: '',
          },
          country_code: {
            label: 'Country',
            value: '',
          },
          provider: {
            label: 'Provider',
            value: '',
          },
          status: {
            label: 'Status',
            items: [
              { _id: '', label: 'No status' },
              { _id: 'active', label: 'active' },
              { _id: 'invalid', label: 'invalid' },
              { _id: 'inactive', label: 'inactive' },
            ],
            value: '',
          },
          free: {
            label: 'Is free',
            value: false,
          },
          failed: {
            label: 'Include Failed',
            value: false,
            show: false,
          },

        },
        loading: false,
        sections: [
          ['proxy_address', 'username', 'password'],
          ['ports', 'country_code', 'provider'],
          ['_id', 'is_used', 'is_valid', 'failed_count'],
        ],
      };
    },
    computed: {
      ...mapGetters({
        proxyList: 'proxy/proxyList',
        proxyFilters: 'proxy/filters',
      }),
    },
    watch: {
      'filters.free.value' () {
        if (this.filters.free.value) {
          this.filters.failed.show = this.filters.free.value;
        } else {
          this.filters.failed.show = false;
          this.filters.failed.value = false;
        }
      },
    },
    async mounted () {
      if (Object.keys(this.$route.query).length) {
        this.setFiltersByQuery('proxy/SET_FILTERS');
        Object.keys(this.filters).forEach((key) => {
          const v = this.proxyFilters[key];
          if (v) { this.filters[key].value = v; };
        });
      } else {
        this.setQueryByFilters(this.proxyFilters, false);
      }

      this.loading = true;
      await this.$store.dispatch('proxy/LOAD_PROXY_LIST');
      this.loading = false;
    },
    methods: {
      async search () {
        try {
          this.loading = true;
          const filters = Object.entries(this.filters).reduce((prev, [k, v]) => {
            prev[k] = v.value;
            return prev;
          }, {});
          this.$store.commit('proxy/SET_FILTERS', filters);
          this.setQueryByFilters(this.proxyFilters, false);
          await this.$store.dispatch('proxy/LOAD_PROXY_LIST');
        } catch (e) {
          console.log(e);
        } finally {
          this.loading = false;
        }
      },
      handleScroll: async function () {
        try {
          const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight + 100 >= document.documentElement.offsetHeight;
          if (bottomOfWindow && this.canLoadMore && !this.loading) {
            this.loading = true;
            const count = this.proxyList.length;
            await this.$store.dispatch('proxy/LOAD_PROXY_LIST', count);
            this.canLoadMore = this.proxyList.length > count;
          }
        } finally {
          this.loading = false;
        }
      },
    },
  };
</script>
